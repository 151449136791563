<template>
    <el-dialog :title="title" :visible.sync="open" width="750px" :append-to-body="true" top="7vh" :close-on-click-modal="false">
        <el-row :gutter="11" >
            <el-form :size="$store.getters.size" :model="form" ref="form" label-width="99px" :rules="rules">
                <el-col :span="24">
                    <el-form-item label="司机头像" prop="headImg">
                        <el-upload
                                class="avatar-uploader"
                                accept=".jpg, .jpeg, .png, .gif, .JPG, .JPEG, .PNG, .GIF"
                                :action="$store.getters.uploadUrl"
                                :on-success="uploadSuccess"
                                :show-file-list="false">
                            <img v-if="form.headImg" :src="form.headImg" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="司机姓名" prop="realName">
                        <el-input type="text" placeholder="请输入司机姓名" v-model="form.realName"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="手机号" prop="phone">
                        <el-input type="text" placeholder="请输入司机手机号" v-model="form.phone" :disabled="form.guid"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="车牌号" prop="vehicle">
                        <el-input type="text" placeholder="司机货车车牌号" v-model="form.vehicle"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="车号" prop="plat">
                        <el-input type="text" placeholder="给该司机排车车号" v-model="form.plat"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="状态" prop="status">
                        <el-radio-group v-model="form.status">
                            <el-radio v-for="item in statusOption" :key="item.value" :label="item.value">{{item.label}}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="性别" prop="sex">
                        <el-radio-group v-model="form.sex">
                            <el-radio v-for="item in sexOption" :key="item.value" :label="item.value">{{item.label}}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="证件类型" prop="cardType">
                        <el-select v-model="form.cardType" style="width: 100%;">
                            <el-option v-for="item in cardTypeOption" :key="item.value" :value="item.value" :label="item.label"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="证件号" prop="cardNo">
                        <el-input type="text" v-model="form.cardNo" placeholder="请输入证件号"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="身份证国徽面" prop="identityCardImg">
                        <el-upload
                                class="avatar-uploader"
                                accept=".jpg, .jpeg, .png, .gif, .JPG, .JPEG, .PNG, .GIF"
                                :action="$store.getters.uploadUrl"
                                :on-success="identityCardImgUploadSuccess"
                                :show-file-list="false">
                            <img v-if="form.identityCardImg" :src="form.identityCardImg" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
<!--                        <div class="img_shade" v-if="form.identityCardImg" @click="bigImgSee">-->
<!--                            <label>大图查看</label>-->
<!--                        </div>-->
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="身份证人像面" prop="identityCardReverseImg">
                        <el-upload
                                class="avatar-uploader"
                                accept=".jpg, .jpeg, .png, .gif, .JPG, .JPEG, .PNG, .GIF"
                                :action="$store.getters.uploadUrl"
                                :on-success="identityCardReverseImgUploadSuccess"
                                :show-file-list="false">
                            <img v-if="form.identityCardReverseImg" :src="form.identityCardReverseImg" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
<!--                        <div class="img_shade" v-if="form.identityCardReverseImg" @click="bigImgSee">-->
<!--                            <label>大图查看</label>-->
<!--                        </div>-->
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="入职日期" prop="entryDate">
                        <el-date-picker style="width: 100%;"
                            v-model="form.entryDate"
                            type="date"
                            :editable="false"
                            value-format="yyyy-MM-dd"
                            placeholder="请选择入职日期">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="生日" prop="birthday">
                        <el-date-picker style="width: 100%;"
                                v-model="form.birthday"
                                type="date"
                                :editable="false"
                                value-format="yyyy-MM-dd"
                                default-value="1999-01-01"
                                placeholder="请选择日期">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="紧急联系人" prop="urgentContactsName">
                        <el-input v-model="form.urgentContactsName" placeholder="请输入紧急联系人姓名" maxlength="5"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="联系人电话" prop="urgentContactsPhone">
                        <el-input v-model="form.urgentContactsPhone" placeholder="请输入紧急联系人电话"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="车辆年检日期" prop="vehicleInspection">
                        <el-date-picker style="width: 100%;"
                                        v-model="form.vehicleInspection"
                                        type="date"
                                        :editable="false"
                                        value-format="yyyy-MM-dd"
                                        default-value="2024-01-01"
                                        placeholder="请选择日期">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="行驶证图片" prop="drivingLicenseImg">
                        <el-upload
                                class="avatar-uploader"
                                accept=".jpg, .jpeg, .png, .gif, .JPG, .JPEG, .PNG, .GIF"
                                :action="$store.getters.uploadUrl"
                                :on-success="drivingLicenseImgUploadSuccess"
                                :show-file-list="false">
                            <img v-if="form.drivingLicenseImg" :src="form.drivingLicenseImg" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item prop="isAdmin">
                        <el-radio-group v-model="form.isAdmin">
                            <el-radio label="Y" key="Y">是</el-radio>
                            <el-radio label="N" key="N">否</el-radio>
                        </el-radio-group>
                        <span slot="label">
                            <el-tooltip content="是否是仓库管理,如果选择是则可以看到该仓库下的所有司机车辆信息" placement="top">
                                <i class="el-icon-question"></i>
                            </el-tooltip>
                            管理员
                        </span>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item prop="employeeType">
                        <el-radio-group v-model="form.employeeType">
                            <el-radio-button label="1" key="1">是</el-radio-button>
                            <el-radio-button label="2" key="2">否</el-radio-button>
                        </el-radio-group>
                        <span slot="label">
                            仓库司机
                        </span>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="保险公司" prop="insureCompany">
                        <el-input v-model="form.insureCompany" placeholder="保险公司"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="保额" prop="insurePrice">
                        <el-input-number v-model="form.insurePrice" placeholder="购买的保险保额" :controls="false" :min="0"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="到期日期" prop="insureDate">
                        <el-date-picker style="width: 100%;"
                            v-model="form.insureDate"
                            placeholder="保险到期日期"
                            type="date"
                            :editable="false"
                            format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="劳动合同" prop="laborContractImg">
                        <el-upload
                                class="avatar-uploader"
                                accept=".jpg, .jpeg, .png, .gif, .JPG, .JPEG, .PNG, .GIF"
                                :action="$store.getters.uploadUrl"
                                :on-success="laborContractImgUploadSuccess"
                                :show-file-list="false">
                            <img v-if="form.laborContractImg" :src="form.laborContractImg" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="保单图片" prop="headImg" >
                        <div style="display: flex">
                            <template v-if="insureImgPaths.length > 0">
                                <div style="position: relative; margin-right: 10px;" v-for="(item, index) in insureImgPaths" :key="index">
                                    <img  :src="item" class="avatar" />
                                    <div class="img_box" @click="delImg(index)">
                                        <i class="el-icon-close"></i>
                                    </div>
                                </div>
                            </template>
                            <el-upload
                                    v-if="insureImgPaths.length < 3"
                                    multiple
                                    class="avatar-uploader"
                                    accept=".jpg, .jpeg, .png, .gif, .JPG, .JPEG, .PNG, .GIF"
                                    :action="$store.getters.uploadUrl"
                                    :on-success="insureImgUploadSuccess"
                                    :show-file-list="false">
                                <i  class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                        </div>

<!--                        <div class="img_shade" v-if="insureImgPaths" @click="bigImgSee">-->
<!--                            <label>大图查看</label>-->
<!--                        </div>-->
                    </el-form-item>
                </el-col>

                <el-col :span="24">
                    <el-form-item label="所属仓库" prop="warehouses">
                        <el-select v-model="form.warehouses" style="width: 100%;" multiple filterable>
                            <el-option v-for="item in warehouseOption" :label="item.label" :key="item.value" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="备注" prop="remark">
                        <el-input type="textarea" placeholder="备注" :autosize="{ minRows: 4, maxRows: 6}" v-model="form.remark"></el-input>
                    </el-form-item>
                </el-col>
            </el-form>
        </el-row>

        <div slot="footer" class="dialog-footer">
            <el-button :size="$store.getters.size" type="primary" @click="submitForm" :loading="loading">确 定</el-button>
            <el-button :size="$store.getters.size" @click="cancel">取 消</el-button>
        </div>
        <image-view ref="imageView" />

    </el-dialog>
</template>

<script>
    import {saveDriver, updateDriver, getDriver} from '@/api/system/driver'
    import ImageView from '@/components/ImageView/'

    export default {
        name: "DriverEdit",
        components: {ImageView},
        data() {
            const getWarehouse = () =>{
                return this.$store.getters.warehouse;
            }
            return {
                form: {},
                insureImgPaths: [],
                open: false,
                title: '',
                loading: false,
                statusOption: [{label: '启用', value: 1}, {label: '禁用', value: 2}],
                sexOption: [{label: '男', value: 1},{label: '女', value: 2},{label: '未知', value: 3}],
                cardTypeOption: [{label: '身份证', value: 1},{label: '护照', value: 2}],
                warehouseOption: getWarehouse(),
                rules:{
                    phone: [{ required: true, message: "司机手机号不能为空", trigger: "blur" }],
                    realName: [{ required: true, message: "司机姓名不能为空", trigger: "blur" }],
                    vehicle: [{ required: true, message: "司机车牌号不能为空", trigger: "blur" }],
                    warehouses: [{ required: true, message: "请选择仓库", trigger: "blur" }],
                    urgentContactsName: [{ required: true, message: "紧急联系人不能为空", trigger: "blur" }],
                    urgentContactsPhone: [{ required: true, message: "紧急联系人电话不能为空", trigger: "blur" }]
                }
            }
        },
        methods: {
            /** 新增司机 */
            handleAdd(){
                this.reset();
                this.open = true;
                this.title = '新增司机';
            },
            handleUpdate(gid){
                this.reset();
                this.open = true;
                this.title = '修改司机';
                getDriver(gid).then(({code, data})=>{
                    if(code === 0){
                        this.form = data;
                        console.log(this.form)
                        this.insureImgPaths = data.insureImgPath.split(',') == '' ? [] : data.insureImgPath.split(',')
                    }
                })
            },

            /** 重置表单 */
            reset(){
                this.form = {
                    phone: undefined,
                    realName: undefined,
                    vehicle: undefined,
                    plat: undefined,
                    status: 1,
                    birthday: undefined,
                    headImg: undefined,
                    sex: 1,
                    warehouses: undefined,
                    cardType: 1,
                    cardNo: undefined,
                    remark: undefined,
                    isAdmin: 'N',
                    employeeType: '2',
                    insurePrice: undefined,
                    insureDate: undefined,
                    insureCompany: undefined,
                    entryDate: undefined,
                    urgentContactsName: undefined,
                    urgentContactsPhone: undefined,
                    insureImgPath: undefined,
                    identityCardImg: undefined,
                    identityCardReverseImg: undefined,
                    vehicleInspection: undefined,
                    drivingLicenseImg: undefined,
                    laborContractImg: undefined,
                }
            },
            /** 关闭 */
            cancel(){
                this.open = false;
                this.reset();
            },
            /** 提交表单 */
            submitForm(){
                this.$refs['form'].validate(valid => {
                    if (valid){
                        this.loading = true;
                        this.form.insureImgPath = this.insureImgPaths.join(',');
                        if(this.form.guid !== undefined){
                            updateDriver(this.form).then(({code})=>{
                                this.loading = false;
                                if(code === 0) {
                                    this.$message.success("修改成功");
                                    this.cancel();
                                    this.$parent.getTableData();
                                }
                            }).catch(()=>{this.loading = false;})
                        }else{
                            saveDriver(this.form).then(({code})=>{
                                this.loading = false;
                                if(code === 0) {
                                    this.$message.success("添加成功");
                                    this.cancel();
                                    this.$parent.getTableData();
                                }
                            }).catch(()=>{this.loading = false;})
                        }
                    }
                })
            },
            /** 图片上传成功 */
            uploadSuccess(response){
                if(response.code === 0){
                    this.form.headImg = response.data;
                }
            },
            /** 保单图片上传成功 */
            insureImgUploadSuccess(response){
                if(response.code === 0){
                    let arr = this.insureImgPaths
                    arr.push(response.data)
                    this.insureImgPaths=arr
                }
            },
            identityCardImgUploadSuccess(response){
                if(response.code === 0){
                    this.form.identityCardImg = response.data;
                }
            },
            identityCardReverseImgUploadSuccess(response){
                if(response.code === 0){
                    this.form.identityCardReverseImg = response.data;
                }
            },
            drivingLicenseImgUploadSuccess(response){
                if(response.code === 0){
                    this.form.drivingLicenseImg = response.data;
                }
            },
            laborContractImgUploadSuccess(response) {
                if (response.code === 0) {
                    this.form.laborContractImg = response.data;
                }
            },
            /** 大图查看 */
            bigImgSee(){
                let imgPath = this.form.insureImgPaths.substring(this.form.insureImgPaths.lastIndexOf('/') + 1)
                this.$refs['imageView'].urls = [this.$store.getters.orgImgUrl + imgPath]
                this.$refs['imageView'].isShow = true;
            },
            delImg(e) {
                let arr = this.insureImgPaths
                arr.splice(e,1)
                this.insureImgPaths = arr
            }
        }
    }
</script>

<style scoped>
.img_shade{position: absolute; left: 155px; top:60px;}
.img_shade :hover{cursor: pointer; color: #36a3f7;}
.img_box{
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 0px 0px 32px;
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 5px;
    box-sizing: border-box;
    background: #000000cf;
}
.img_box i{
    font-size: 20px;
    cursor: pointer;
    color: rgb(255 255 255);
}
</style>
