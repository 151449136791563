<template>
    <div v-if="isShow">
        <el-image-viewer :url-list="getUrls()" :initial-index="index" :on-close="close" :maskClosable="false" :zIndex="3001"/>
    </div>
</template>

<script>
    import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
    export default {
        name: "ImageView",
        components: {ElImageViewer},
        data(){
            return{
                urls: [],
                index: 0,
                isShow: false
            }
        },
        methods: {
            getUrls(){
                if(this.urls.length > 0){
                    return this.urls.map(item => {
                        if(item.indexOf(process.env.VUE_APP_CDN_PATH) !== -1){
                            if(item.lastIndexOf(".") < 30) {
                              const split = item.slice(item.lastIndexOf("/") + 1);
                              return process.env.VUE_APP_GET_IMG_PATH + split;
                            }else {
                              const dataPath = item.replace(process.env.VUE_APP_CDN_PATH, process.env.VUE_APP_GET_IMG_PATH);
                              return dataPath
                            }
                        }
                        return item;
                    })
                }
                return [];
            },
            close(){
                this.isShow = false
            }
        }
    }
</script>

<style scoped>

</style>
